"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContainerPropertiesToJSON = exports.ContainerPropertiesFromJSONTyped = exports.ContainerPropertiesFromJSON = exports.instanceOfContainerProperties = void 0;
var runtime_1 = require("../runtime");
var ContainerField_1 = require("./ContainerField");
var ContainerState_1 = require("./ContainerState");
var EntityInfo_1 = require("./EntityInfo");
var ValidationErrorResult_1 = require("./ValidationErrorResult");
/**
 * Check if a given object implements the ContainerProperties interface.
 */
function instanceOfContainerProperties(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfContainerProperties = instanceOfContainerProperties;
function ContainerPropertiesFromJSON(json) {
    return ContainerPropertiesFromJSONTyped(json, false);
}
exports.ContainerPropertiesFromJSON = ContainerPropertiesFromJSON;
function ContainerPropertiesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'templateId': !(0, runtime_1.exists)(json, 'templateId') ? undefined : json['templateId'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'issuer': !(0, runtime_1.exists)(json, 'issuer') ? undefined : (0, EntityInfo_1.EntityInfoFromJSON)(json['issuer']),
        'recipient': !(0, runtime_1.exists)(json, 'recipient') ? undefined : (0, EntityInfo_1.EntityInfoFromJSON)(json['recipient']),
        'completed': !(0, runtime_1.exists)(json, 'completed') ? undefined : json['completed'],
        'completenessFailures': !(0, runtime_1.exists)(json, 'completenessFailures') ? undefined : (json['completenessFailures'].map(ValidationErrorResult_1.ValidationErrorResultFromJSON)),
        'active': !(0, runtime_1.exists)(json, 'active') ? undefined : json['active'],
        'activenessFailures': !(0, runtime_1.exists)(json, 'activenessFailures') ? undefined : (json['activenessFailures'].map(ValidationErrorResult_1.ValidationErrorResultFromJSON)),
        'state': !(0, runtime_1.exists)(json, 'state') ? undefined : (0, ContainerState_1.ContainerStateFromJSON)(json['state']),
        'createdAt': !(0, runtime_1.exists)(json, 'createdAt') ? undefined : json['createdAt'],
        'acceptedAt': !(0, runtime_1.exists)(json, 'acceptedAt') ? undefined : json['acceptedAt'],
        'authorizedAt': !(0, runtime_1.exists)(json, 'authorizedAt') ? undefined : json['authorizedAt'],
        'rejectedAt': !(0, runtime_1.exists)(json, 'rejectedAt') ? undefined : json['rejectedAt'],
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : json['updatedAt'],
        'fields': !(0, runtime_1.exists)(json, 'fields') ? undefined : (json['fields'].map(ContainerField_1.ContainerFieldFromJSON)),
    };
}
exports.ContainerPropertiesFromJSONTyped = ContainerPropertiesFromJSONTyped;
function ContainerPropertiesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'templateId': value.templateId,
        'name': value.name,
        'description': value.description,
        'issuer': (0, EntityInfo_1.EntityInfoToJSON)(value.issuer),
        'recipient': (0, EntityInfo_1.EntityInfoToJSON)(value.recipient),
        'completed': value.completed,
        'completenessFailures': value.completenessFailures === undefined ? undefined : (value.completenessFailures.map(ValidationErrorResult_1.ValidationErrorResultToJSON)),
        'active': value.active,
        'activenessFailures': value.activenessFailures === undefined ? undefined : (value.activenessFailures.map(ValidationErrorResult_1.ValidationErrorResultToJSON)),
        'state': (0, ContainerState_1.ContainerStateToJSON)(value.state),
        'createdAt': value.createdAt,
        'acceptedAt': value.acceptedAt,
        'authorizedAt': value.authorizedAt,
        'rejectedAt': value.rejectedAt,
        'updatedAt': value.updatedAt,
        'fields': value.fields === undefined ? undefined : (value.fields.map(ContainerField_1.ContainerFieldToJSON)),
    };
}
exports.ContainerPropertiesToJSON = ContainerPropertiesToJSON;
