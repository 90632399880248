"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtendPolicyPropertiesToJSON = exports.ExtendPolicyPropertiesFromJSONTyped = exports.ExtendPolicyPropertiesFromJSON = exports.instanceOfExtendPolicyProperties = void 0;
var runtime_1 = require("../runtime");
var Atom_1 = require("./Atom");
var PermissionPair_1 = require("./PermissionPair");
/**
 * Check if a given object implements the ExtendPolicyProperties interface.
 */
function instanceOfExtendPolicyProperties(value) {
    var isInstance = true;
    isInstance = isInstance && "sourcePolicyID" in value;
    return isInstance;
}
exports.instanceOfExtendPolicyProperties = instanceOfExtendPolicyProperties;
function ExtendPolicyPropertiesFromJSON(json) {
    return ExtendPolicyPropertiesFromJSONTyped(json, false);
}
exports.ExtendPolicyPropertiesFromJSON = ExtendPolicyPropertiesFromJSON;
function ExtendPolicyPropertiesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'sourcePolicyID': json['sourcePolicyID'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'trueMessage': !(0, runtime_1.exists)(json, 'trueMessage') ? undefined : json['trueMessage'],
        'falseMessage': !(0, runtime_1.exists)(json, 'falseMessage') ? undefined : json['falseMessage'],
        'permissions': !(0, runtime_1.exists)(json, 'permissions') ? undefined : (json['permissions'].map(PermissionPair_1.PermissionPairFromJSON)),
        'rule': !(0, runtime_1.exists)(json, 'rule') ? undefined : (json['rule'].map(Atom_1.AtomFromJSON)),
    };
}
exports.ExtendPolicyPropertiesFromJSONTyped = ExtendPolicyPropertiesFromJSONTyped;
function ExtendPolicyPropertiesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'sourcePolicyID': value.sourcePolicyID,
        'name': value.name,
        'description': value.description,
        'trueMessage': value.trueMessage,
        'falseMessage': value.falseMessage,
        'permissions': value.permissions === undefined ? undefined : (value.permissions.map(PermissionPair_1.PermissionPairToJSON)),
        'rule': value.rule === undefined ? undefined : (value.rule.map(Atom_1.AtomToJSON)),
    };
}
exports.ExtendPolicyPropertiesToJSON = ExtendPolicyPropertiesToJSON;
