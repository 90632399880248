"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplatePatchToJSON = exports.TemplatePatchFromJSONTyped = exports.TemplatePatchFromJSON = exports.instanceOfTemplatePatch = exports.TemplatePatchStateEnum = void 0;
var runtime_1 = require("../runtime");
var PermissionPair_1 = require("./PermissionPair");
var TemplatePatchRules_1 = require("./TemplatePatchRules");
/**
 * @export
 */
exports.TemplatePatchStateEnum = {
    Paused: 'paused',
    Live: 'live'
};
/**
 * Check if a given object implements the TemplatePatch interface.
 */
function instanceOfTemplatePatch(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfTemplatePatch = instanceOfTemplatePatch;
function TemplatePatchFromJSON(json) {
    return TemplatePatchFromJSONTyped(json, false);
}
exports.TemplatePatchFromJSON = TemplatePatchFromJSON;
function TemplatePatchFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'state': !(0, runtime_1.exists)(json, 'state') ? undefined : json['state'],
        'permissions': !(0, runtime_1.exists)(json, 'permissions') ? undefined : (json['permissions'].map(PermissionPair_1.PermissionPairFromJSON)),
        'rules': !(0, runtime_1.exists)(json, 'rules') ? undefined : (0, TemplatePatchRules_1.TemplatePatchRulesFromJSON)(json['rules']),
        'autoApprove': !(0, runtime_1.exists)(json, 'autoApprove') ? undefined : json['autoApprove'],
        'autoRevoke': !(0, runtime_1.exists)(json, 'autoRevoke') ? undefined : json['autoRevoke'],
    };
}
exports.TemplatePatchFromJSONTyped = TemplatePatchFromJSONTyped;
function TemplatePatchToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'description': value.description,
        'state': value.state,
        'permissions': value.permissions === undefined ? undefined : (value.permissions.map(PermissionPair_1.PermissionPairToJSON)),
        'rules': (0, TemplatePatchRules_1.TemplatePatchRulesToJSON)(value.rules),
        'autoApprove': value.autoApprove,
        'autoRevoke': value.autoRevoke,
    };
}
exports.TemplatePatchToJSON = TemplatePatchToJSON;
