"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyRequestPropertiesToJSON = exports.PolicyRequestPropertiesFromJSONTyped = exports.PolicyRequestPropertiesFromJSON = exports.instanceOfPolicyRequestProperties = exports.PolicyRequestPropertiesStatusEnum = void 0;
var runtime_1 = require("../runtime");
/**
 * @export
 */
exports.PolicyRequestPropertiesStatusEnum = {
    Approved: 'Approved',
    Completed: 'Completed',
    Denied: 'Denied',
    Requested: 'Requested'
};
/**
 * Check if a given object implements the PolicyRequestProperties interface.
 */
function instanceOfPolicyRequestProperties(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfPolicyRequestProperties = instanceOfPolicyRequestProperties;
function PolicyRequestPropertiesFromJSON(json) {
    return PolicyRequestPropertiesFromJSONTyped(json, false);
}
exports.PolicyRequestPropertiesFromJSON = PolicyRequestPropertiesFromJSON;
function PolicyRequestPropertiesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'policy': !(0, runtime_1.exists)(json, 'policy') ? undefined : json['policy'],
        'result': !(0, runtime_1.exists)(json, 'result') ? undefined : json['result'],
        'resultMessage': !(0, runtime_1.exists)(json, 'resultMessage') ? undefined : json['resultMessage'],
        'runTimestamp': !(0, runtime_1.exists)(json, 'runTimestamp') ? undefined : json['runTimestamp'],
        'status': !(0, runtime_1.exists)(json, 'status') ? undefined : json['status'],
        'target': !(0, runtime_1.exists)(json, 'target') ? undefined : json['target'],
    };
}
exports.PolicyRequestPropertiesFromJSONTyped = PolicyRequestPropertiesFromJSONTyped;
function PolicyRequestPropertiesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'policy': value.policy,
        'result': value.result,
        'resultMessage': value.resultMessage,
        'runTimestamp': value.runTimestamp,
        'status': value.status,
        'target': value.target,
    };
}
exports.PolicyRequestPropertiesToJSON = PolicyRequestPropertiesToJSON;
