"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AtomToJSON = exports.AtomFromJSONTyped = exports.AtomFromJSON = exports.instanceOfAtom = exports.AtomPredicateEnum = void 0;
var runtime_1 = require("../runtime");
/**
 * @export
 */
exports.AtomPredicateEnum = {
    AfterThisDate: 'AfterThisDate',
    AfterThisDatetime: 'AfterThisDatetime',
    AfterThisTimeOfDay: 'AfterThisTimeOfDay',
    AfterToday: 'AfterToday',
    BeforeNow: 'BeforeNow',
    BeforeNowMinusXDuration: 'BeforeNowMinusXDuration',
    BeforeThisDate: 'BeforeThisDate',
    BeforeThisDatetime: 'BeforeThisDatetime',
    BeforeThisTimeOfDay: 'BeforeThisTimeOfDay',
    BeforeToday: 'BeforeToday',
    BeforeTodayMinusXDays: 'BeforeTodayMinusXDays',
    EqualToX: 'EqualToX',
    FieldHasValue: 'FieldHasValue',
    IsEmailDomain: 'IsEmailDomain',
    IsFalse: 'IsFalse',
    IsTrue: 'IsTrue',
    LessThanX: 'LessThanX',
    MatchesThisString: 'MatchesThisString',
    MoreThanX: 'MoreThanX',
    MappableTo: 'MappableTo',
    ReceivedXContainersFromTemplates: 'ReceivedXContainersFromTemplates',
    ReceivedContainersFromXTemplates: 'ReceivedContainersFromXTemplates'
};
/**
 * Check if a given object implements the Atom interface.
 */
function instanceOfAtom(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfAtom = instanceOfAtom;
function AtomFromJSON(json) {
    return AtomFromJSONTyped(json, false);
}
exports.AtomFromJSON = AtomFromJSON;
function AtomFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'target': !(0, runtime_1.exists)(json, 'target') ? undefined : json['target'],
        'predicate': !(0, runtime_1.exists)(json, 'predicate') ? undefined : json['predicate'],
        'arguments': !(0, runtime_1.exists)(json, 'arguments') ? undefined : json['arguments'],
        'errorMessage': !(0, runtime_1.exists)(json, 'errorMessage') ? undefined : json['errorMessage'],
    };
}
exports.AtomFromJSONTyped = AtomFromJSONTyped;
function AtomToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'target': value.target,
        'predicate': value.predicate,
        'arguments': value.arguments,
        'errorMessage': value.errorMessage,
    };
}
exports.AtomToJSON = AtomToJSON;
