"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtendTemplatePropertiesToJSON = exports.ExtendTemplatePropertiesFromJSONTyped = exports.ExtendTemplatePropertiesFromJSON = exports.instanceOfExtendTemplateProperties = void 0;
var runtime_1 = require("../runtime");
var ExtendTemplatePropertiesRules_1 = require("./ExtendTemplatePropertiesRules");
var PermissionPair_1 = require("./PermissionPair");
/**
 * Check if a given object implements the ExtendTemplateProperties interface.
 */
function instanceOfExtendTemplateProperties(value) {
    var isInstance = true;
    isInstance = isInstance && "sourceTemplateID" in value;
    isInstance = isInstance && "name" in value;
    return isInstance;
}
exports.instanceOfExtendTemplateProperties = instanceOfExtendTemplateProperties;
function ExtendTemplatePropertiesFromJSON(json) {
    return ExtendTemplatePropertiesFromJSONTyped(json, false);
}
exports.ExtendTemplatePropertiesFromJSON = ExtendTemplatePropertiesFromJSON;
function ExtendTemplatePropertiesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'sourceTemplateID': json['sourceTemplateID'],
        'name': json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'permissions': !(0, runtime_1.exists)(json, 'permissions') ? undefined : (json['permissions'].map(PermissionPair_1.PermissionPairFromJSON)),
        'rules': !(0, runtime_1.exists)(json, 'rules') ? undefined : (0, ExtendTemplatePropertiesRules_1.ExtendTemplatePropertiesRulesFromJSON)(json['rules']),
        'autoApprove': !(0, runtime_1.exists)(json, 'autoApprove') ? undefined : json['autoApprove'],
        'autoRevoke': !(0, runtime_1.exists)(json, 'autoRevoke') ? undefined : json['autoRevoke'],
    };
}
exports.ExtendTemplatePropertiesFromJSONTyped = ExtendTemplatePropertiesFromJSONTyped;
function ExtendTemplatePropertiesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'sourceTemplateID': value.sourceTemplateID,
        'name': value.name,
        'description': value.description,
        'permissions': value.permissions === undefined ? undefined : (value.permissions.map(PermissionPair_1.PermissionPairToJSON)),
        'rules': (0, ExtendTemplatePropertiesRules_1.ExtendTemplatePropertiesRulesToJSON)(value.rules),
        'autoApprove': value.autoApprove,
        'autoRevoke': value.autoRevoke,
    };
}
exports.ExtendTemplatePropertiesToJSON = ExtendTemplatePropertiesToJSON;
