"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyRequestToJSON = exports.PolicyRequestFromJSONTyped = exports.PolicyRequestFromJSON = exports.instanceOfPolicyRequest = exports.PolicyRequestStatusEnum = void 0;
var runtime_1 = require("../runtime");
var EntityInfo_1 = require("./EntityInfo");
/**
 * @export
 */
exports.PolicyRequestStatusEnum = {
    Approved: 'Approved',
    Completed: 'Completed',
    Denied: 'Denied',
    Requested: 'Requested'
};
/**
 * Check if a given object implements the PolicyRequest interface.
 */
function instanceOfPolicyRequest(value) {
    var isInstance = true;
    isInstance = isInstance && "owner" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
exports.instanceOfPolicyRequest = instanceOfPolicyRequest;
function PolicyRequestFromJSON(json) {
    return PolicyRequestFromJSONTyped(json, false);
}
exports.PolicyRequestFromJSON = PolicyRequestFromJSON;
function PolicyRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'owner': (0, EntityInfo_1.EntityInfoFromJSON)(json['owner']),
        'createdAt': json['createdAt'],
        'policy': !(0, runtime_1.exists)(json, 'policy') ? undefined : json['policy'],
        'result': !(0, runtime_1.exists)(json, 'result') ? undefined : json['result'],
        'resultMessage': !(0, runtime_1.exists)(json, 'resultMessage') ? undefined : json['resultMessage'],
        'runTimestamp': !(0, runtime_1.exists)(json, 'runTimestamp') ? undefined : json['runTimestamp'],
        'status': !(0, runtime_1.exists)(json, 'status') ? undefined : json['status'],
        'target': !(0, runtime_1.exists)(json, 'target') ? undefined : json['target'],
    };
}
exports.PolicyRequestFromJSONTyped = PolicyRequestFromJSONTyped;
function PolicyRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'owner': (0, EntityInfo_1.EntityInfoToJSON)(value.owner),
        'createdAt': value.createdAt,
        'policy': value.policy,
        'result': value.result,
        'resultMessage': value.resultMessage,
        'runTimestamp': value.runTimestamp,
        'status': value.status,
        'target': value.target,
    };
}
exports.PolicyRequestToJSON = PolicyRequestToJSON;
