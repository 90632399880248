"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyUpdateToJSON = exports.PolicyUpdateFromJSONTyped = exports.PolicyUpdateFromJSON = exports.instanceOfPolicyUpdate = exports.PolicyUpdateStateEnum = void 0;
var runtime_1 = require("../runtime");
var Atom_1 = require("./Atom");
var PermissionPair_1 = require("./PermissionPair");
/**
 * @export
 */
exports.PolicyUpdateStateEnum = {
    Live: 'live',
    Paused: 'paused'
};
/**
 * Check if a given object implements the PolicyUpdate interface.
 */
function instanceOfPolicyUpdate(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfPolicyUpdate = instanceOfPolicyUpdate;
function PolicyUpdateFromJSON(json) {
    return PolicyUpdateFromJSONTyped(json, false);
}
exports.PolicyUpdateFromJSON = PolicyUpdateFromJSON;
function PolicyUpdateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'falseMessage': !(0, runtime_1.exists)(json, 'falseMessage') ? undefined : json['falseMessage'],
        'trueMessage': !(0, runtime_1.exists)(json, 'trueMessage') ? undefined : json['trueMessage'],
        'permissions': !(0, runtime_1.exists)(json, 'permissions') ? undefined : (json['permissions'] === null ? null : json['permissions'].map(PermissionPair_1.PermissionPairFromJSON)),
        'rule': !(0, runtime_1.exists)(json, 'rule') ? undefined : (json['rule'].map(Atom_1.AtomFromJSON)),
        'state': !(0, runtime_1.exists)(json, 'state') ? undefined : json['state'],
    };
}
exports.PolicyUpdateFromJSONTyped = PolicyUpdateFromJSONTyped;
function PolicyUpdateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'description': value.description,
        'falseMessage': value.falseMessage,
        'trueMessage': value.trueMessage,
        'permissions': value.permissions === undefined ? undefined : (value.permissions === null ? null : value.permissions.map(PermissionPair_1.PermissionPairToJSON)),
        'rule': value.rule === undefined ? undefined : (value.rule.map(Atom_1.AtomToJSON)),
        'state': value.state,
    };
}
exports.PolicyUpdateToJSON = PolicyUpdateToJSON;
